<template>
  <div v-loading.fullscreen.lock="loading" class="elv-oauth-container"></div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
// eslint-disable-next-line no-unused-vars
import ReportsApi from '@/api/ReportsApi'

// eslint-disable-next-line no-unused-vars
const { t } = useI18n()
const route = useRoute()
// eslint-disable-next-line no-unused-vars
const router = useRouter()

const loading = ref(true)

onMounted(async () => {
  try {
    console.log(route)
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  }
})
</script>

<style lang="scss" scoped>
.elv-oauth-container {
  width: 100vw;
  height: 100vh;
}
</style>
